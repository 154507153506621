$sidebar-bg-color: #ccc !default;
$sidebar-width: 100% !default;
$submenu-bg-color: #ccc !default;
$icon-bg-color: #ccc !default;
$sidebar-color: #000 !default;
$highlight-color: #5E4DA0 !default;
$submenu-bg-color-collapsed: #ccc !default;
.pro-sidebar{
    z-index: 799 !important;
}
@import '~react-pro-sidebar/dist/scss/styles.scss';
// .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper,
// .pro-sidebar > .pro-sidebar-inner {
//     background-color: #f8f9fa !important;
// }
// .pro-sidebar{
//     color: #000 !important;
// }

