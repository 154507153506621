/*
* @Author: pradeep kumar baranwal
* @Date:   2020-11-30 00:35:23
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-10 01:10:17
*/
.header-top {
  background: #e7e7e8;
 }
 @media screen and (min-width: 992px) {
    /*.navbar-expand-lg .navbar-nav {
        flex-direction : row-reverse !important;
    }*/
 }