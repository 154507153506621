@import url("https://fonts.googleapis.com/css2?family=Fontdiner+Swanky&family=Roboto:wght@500&display=swap");

$lime: #c5dc50;
$rasp: #f36a6f;
$seed: #383838;
$sm: 1rem;
$md: 2.5rem;
$lg: 4rem;
$vh:80vh;

.notFoundBody {
  background: linear-gradient(to right, white 50%, $seed 50%);
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: white;
  > * {
    box-sizing: 0;
    margin: 0;
    padding: 0;
    cursor: url("../../assets/img/cursors-edge.png"),
        auto;
    }
}

div.NotFoundWrapper {
  display: flex;
  align-items: center;
  height: $vh;
  max-width: 1000px;
  width: calc(100% - #{$lg});
  margin: 0 auto;
  > * {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: $vh;
    max-width: 500px;
    width: 100%;
    padding: $md;
  }
}

aside {
  background-image: url("../../assets/img/right-edges.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 25px 100%;
  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

main {
  text-align: center;
  h1 {
    font-family: "Fontdiner Swanky", cursive;
    font-size: $lg;
    color: $lime;
    margin-bottom: $sm;
  }
  p {
    margin-bottom: $md;
    em {
      font-style: italic;
      color: $lime;
    }
  }
  a {
    font-family: "Fontdiner Swanky", cursive;
    font-size: $sm;
    color: $seed;
    border: none;
    background-color: $rasp;
    padding: $sm $md;
    transform: skew(-5deg);
    transition: all 0.1s ease;
    cursor: url("../../assets/img/cursors-eye.png"),
      auto;
    &:hover {
        text-decoration: none;
      background-color: $lime;
      transform: scale(1.15);
    }
  }
}

@media (max-width: 700px) {
  .notFoundBody {
    background: $seed;
    font-size: 16px;
  }
  div.notFoundBody {
    flex-flow: column;
    > * {
      max-width: 700px;
      height: 100%;
    }
  }
  aside {
    background-image: none;
    background-color: white;
    img {
      max-width: 300px;
    }
  }
}
