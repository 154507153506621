@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900&display=swap";
body {
  font-family: 'Merriweather', serif; 
}


.footer {
  /* background: url(../img/footer-bg.jpg) center center; */
  background-repeat: no-repeat;
  background-size: cover; }

.ft-midd {
  text-align: center;
  padding: 30px 0; }

.ft-list {
  padding: 30px 0; }

.ft-list ul {
  margin: 0;
  padding: 0; }

.ft-list ul li {
  list-style: none;
  padding: 3px 0;
  text-decoration: none; }

.ft-list ul li a {
  color: #302d27; }

.bn-text {
  position: absolute;
  top: 160px;
  left: 14%; }
/*Stallernav*/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

/* mega dd */
/* Fallback for slow javascript load */
@media only screen and (max-width: 768px) {
  .ft-list {
    text-align: center;
    padding: 0px 0; }
}



/*# sourceMappingURL=main.css.map */
