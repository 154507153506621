.user_bg {
    background-image: url("../../../assets/img/user_bg.jpg");
}


// .App {
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     justify-items: center;
//     font-size: 22px;
//     max-width: 500px;
//     min-height: 672px;
//     margin: 0 auto;
//     border-radius: 25px;
//     color: white;
//     background-color: rgba(0, 0, 0, 0.5);
//   }
  
//   .App > input {
//     margin-top: 200px;
//   }
  
//   input {
//     margin: 0 auto;
//     background-color: black;
//     opacity: 50%;
//     color: white;
//     margin-top: 10px;
//     width: 300px;
//     height: 59px;
//     font-size: 22px;
//     margin-bottom: 14px;
//     max-width: 527px;
//     padding: 0 10%;
//   }
  
//   .pass-wrapper {
//     position: relative;
//     display: flex;
//     margin-bottom: 14px;
//   }
  
  i.showHideEye {
    position: absolute;
    top: 51%;
    right: 4%;
  }
  i.showHideEye:hover {
    // color: #00fcb6;
    cursor: pointer;
  }
  
//   button {
//     width: 300px;
//     height: 50px;
//     margin: 0 auto;
//   }
  