/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-07 16:55:56
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-10 21:56:37
*/
@media screen and (min-width: 300px) and (max-width: 567px) {
    .onus-pt-sm-1 {
        padding-top:0.25rem !important;
    }
    .onus-pt-sm-2 {
        padding-top:0.50rem !important;
    }
}

._loading_overlay_overlay{
    position:fixed !important;
    overflow:hidden;
}