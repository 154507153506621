.card-body .point {
    padding: 10px 0px;
    background: #5e4da0;
    font-size: 2em;
    font-weight: bold;
    color: #fff;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    margin-top: 25px;
    line-height: 28px;
    text-align: center;
}