/*
* @Author: pradeep kumar baranwal
* @Date:   2020-11-30 16:26:08
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-10 01:42:33
*/

.carousel-control-prev-icon{
  width: 26px !important;
  height: 48px !important;
  background: url(../../../assets/img/left-arrow.png) no-repeat !important;
}
.carousel-control-next-icon {
  width: 26px !important;
  height: 48px !important;
  background: url(../../../assets/img/right-arrow.png) no-repeat !important;  
}
.carousel-caption {
  left: 16% !important;
  top: 16% !important; 
  text-align:left !important;
}
.carousel-indicators{
    z-index:1 !important;
}

.bnr-bl-bg {
  background: #58585a;
  padding: 35px 0; 
}
.bnr-bl-bg .bn-heading {
    font-size: 18px;
    color: #fff;
    padding: 9px 0; 
}
.bnr-bl-bg .join-now a {
    background: #82bb41;
    text-align: center;
    display: inline-block;
    padding: 10px 40px;
    color: #fff;
    border-radius: 3px;
    text-decoration: none;
    font-size: 16px; 
}
.bnr-bl-bg .join-now a:hover {
    background: #000; 
}
.bn-small-text{
    padding: 40% 0 0 0;
}

.bn-small-text h1 {
  font-size: 34px;
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: #fff; }
.bn-small-text p {
  font-size: 18px;
  color: #fff; }

.singup {
  text-align: center;
  margin-top: 20px; }

.singup a {
  background: #58585a;
  color: #fff;
  padding: 10px 40px;
  display: inline-block;
  border-radius: 3px; 
}

.singup a:hover {
  background: #82bb41;
  color: #fff;
  text-decoration: none; 
}
img {
  max-width: 100%; }
@media only screen and (max-width: 768px) {
    .bn-small-text {
        position: absolute;
        top: 0;
        left: 25%;
    }
    
    .bn-small-text h1 {
        font-size: 15px;
    }

    .bn-small-text p {
        font-size: 9px;
    }
    .singup {
        text-align: left;
        margin-top: 3px;
    }

    .singup a {
        padding: 6px 20px;
        font-size: 10px;
        margin-left: 25%;
    }
    .bnr-bl-bg {
        text-align: center; 
    }
    .bn-fnt-sec img {
        // width: 55%;
    }
}
@media only screen and (max-width: 411px) and (min-width:400px) {
  .carousel-caption{
    padding-top: 0 !important;
  }
  .bn-fnt-sec,.small-width {
    width:50% !important;
  }
  .bn-fnt-sec img{
    width:80%;
  }
  .bn-small-text {
    left: auto;
    padding:15% 0 0 0 !important;
  }
  .singup a {
    margin-left: 0;
  }
}
@media only screen and (max-width: 414px) and (min-width:376px) {
  .carousel-caption{
    padding-top: 0 !important;
  }
  .bn-fnt-sec,.small-width {
    width:50% !important;
  }
  .bn-fnt-sec img{
    width:80%;
  }
  .bn-small-text {
    left: auto;
    padding:15% 0 0 0 !important;
  }
  .singup a {
    margin-left: 0;
  }
}
@media only screen and (max-width: 375px) {
  .carousel-caption{
    padding-top: 0px !important;
  }
  .bn-fnt-sec img{
    width:40%;
  }
  .singup a {
    padding:6px 18px;
    margin-left: 0;
  }
  .bn-small-text {
    padding:5% 0 0 0;
    left:45%;
  }
}