.Footer{
    background:  url(../../assets/img/footer-bg.jpg) no-repeat top center ;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    .container{
        padding-top: 25px;      
    }
}
.ft-list ul li{
    list-style: none;
    
    a{
        color: black;
    }
}

.ft-about {
  text-align: center;
  padding: 30px 0; 
}
.ft-about p {
    color: #302d27; 
}
