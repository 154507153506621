/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-03 23:36:05
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-03 23:40:04
*/
.heading-midd {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 3px;
  padding: 15px 0; }

h2.heading-midd{
  margin-top: 20px;
margin-bottom: 10px;
}

.box-heading {
  text-align: center;
  background: #ecefe8;
  padding: 201px 0; }

.box-heading a {
  background: #82bb41;
  padding: 6px 40px;
  display: inline-block;
  color: #222;
  font-size: 24px;
  border-radius: 3px;
  margin-bottom: 10px;
  text-decoration: none; }

.box-heading a:hover {
  text-decoration: none;
  cursor: none;
  // background: #000;
  // color: #fff; 
}


.no-padd {
  padding-left: 0 !important;
  padding-right: 0 !important; 
}

.midd-img img {
  width: 100%; 
}

.row-padd {
  margin-right: 0 !important;
  margin-left: 0 !important; 
}


@media only screen and (max-width: 768px) {
    .box-heading {
    padding: 40px 0; }
}

@media only screen and (max-width: 1000px) {
  .box-heading{
    padding:48px;
  }
  .box-heading a{
    padding-left:20px;
    padding-right:20px;
  }
}
@media only screen and (max-width: 800px) {
  .box-heading{
    padding:40px;
  }
  
}
@media only screen and (max-width: 768px) {
  .box-heading{
    padding:40px;
  }
  
}
/* mobile nav */
@media only screen and (max-width: 420px) {
  .left {
      order:2;
  }

  .right {
      order:1;
  }
}